
import { Options, Vue } from "vue-class-component";
import NewsPage from "@/components/PC/NewsPage.vue";
import { kMobileComponyInfo } from "@/config/news"

@Options({
  components: {
    NewsPage,
  },
  data() {
    return {
      items: kMobileComponyInfo.swly,
    };
  },
})
export default class Liyuan extends Vue {}
